import {
  MutationRemoveCategoryArgs,
  RemoveCategoryOutput,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { CATEGORY_FIELDS } from "@/graphql/category/category";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { useConfirm } from "primevue/useconfirm";

type RemoveCategoryData = {
  removeCategory: RemoveCategoryOutput;
};

const REMOVE_CATEGORY = gql`
    mutation RemoveCategory($id: Int!) {
        removeCategory(id: $id) {
          children {
            ${CATEGORY_FIELDS}
          }
          maskedProducts {
            id
            category {
              id
              label
            }
          }
        }
    }
`;
export const useRemoveCategory = () => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();
  const {
    loading: loadRemove,
    mutate,
    onDone,
  } = useMutation<RemoveCategoryData, MutationRemoveCategoryArgs>(
    REMOVE_CATEGORY
  );
  onDone(({ data }) => {
    const success = !!data?.removeCategory;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("delete.title"),
      detail: t(`delete.${success ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  function submitRemove(id: number) {
    const data = {
      message: t("category.warning"),
      header: t("delete.title"),
      group: "dialog",
      accept: () =>
        void mutate(
          { id },
          {
            update(cache, { data }) {
              if (data?.removeCategory) {
                cache.modify({
                  fields: {
                    productCategories(existingRef: any[], { readField }) {
                      return existingRef.filter(
                        (eRef) => readField("id", eRef) !== id
                      );
                    },
                  },
                });
              }
            },
          }
        ),
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-warning",
    };
    confirm.require(data);
  }
  return { loadRemove, submitRemove };
};
